html,
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

svg {
	display: inline !important;
}

.wrapper-enter-active {
	padding: 0 !important;
}

.wrapper-enter {
	padding: 0 !important;
}

.wrapper {
	padding: 0 !important;
}

.select {
	border: none !important;
}

.cmainclass {
	max-height: 75vh !important;
	min-height: 75vh !important;
	margin-top: 4vh !important;
}

.cmainclass-dashboard {
	max-height: 70vh !important;
	min-height: 70vh !important;
	margin-top: 10vh !important;
}
